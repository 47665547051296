// import algoSoft from "../../images/homeImage/software.png"
import algoSoft from "../../images/homeImage/algosoft.jpg"

import {Link} from 'react-router-dom';

const AlgoTradeSoftware = () => {
    return (
        <div className="bg-[#092638] lg:w-[100%] text-white p-8 lg:flex items-center lg:justify-center shadow-lg  mx-auto">
          {/* Text Section */}
          <div className="lg:w-[50%]">
            <h2 className="text-4xl font-bold text-green-700 mb-4">
              ALGO TRADE SOFTWARE
            </h2>
            <p className="text-lg mb-6">
              Decodiefy Technologies is a third-party trading system developer
              specializing in automated trading systems, algorithmic trading strategies,
              trading algorithm design, and quantitative trading analysis. We offer a fully
              automated trading system that allows both retail and professional investors to
              take advantage of market inefficiencies by placing high-probability trades when
              the trading system determines there is an edge to be had. Being strategic, our
              developer is sufficiently skilled to trick the software and has the necessary
              programming and well-tested capacity. Decodiefy Technologies is the best
              algorithmic system developer in India. Please do experience our services once.
            </p>
            <Link
              to="/about"
              className="inline-block bg-green-700 hover:bg-green-400 text-white py-2 px-4 rounded-full text-center font-semibold hover:scale-105 transition-transform duration-500 ease-in-out"
            >
              CLICK HERE
            </Link>
          </div>
    
          {/* Image Section */}
          <div className="lg:w-1/4 lg:ml-8 mt-8 lg:mt-0">
            <img
              src={algoSoft}
              alt="Algo Trade Software"
              className="rounded-lg shadow-lg hover:scale-95 transition-transform duration-500 ease-in-out object-cover"
              loading="lazy"
            />
          </div>
        </div>
    );
}

export default AlgoTradeSoftware;