import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// import logo from '../../images/logo.jpg'
import logo from '../../images/favicon-removebg.png'
const Footer = () => {
    // bg-[#1A1D2B]
    return (
        <footer className="bg-[#192329]">
            <div className="container mx-auto p-0 md:p-8 xl:px-0">
                <div className="mx-auto max-w-7xl px-6 pb-10 pt-16">
                    <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                        <div className="space-y-4">
                            <div>
                                <Link to="/"> {/* Changed to Link */}
                                    <div className="flex items-center space-x-2 text-2xl font-medium">
                                        <span>
                                            <img className="w-auto h-28 text-white" src={logo} loading="lazy" />
                                        </span>
                                        <br />
                                        {/* <div>
                                            <h1 className="text-white sm:text-2xl md:text-3xl lg:text-3xl font-bold">Decodiefy</h1>
                                            <h1 className="text-gray-400 font-bold sm:text-2xl md:text-3xl lg:text-3xl">Technologies</h1>
                                        </div> */}
                                    </div>
                                </Link>
                            </div>
                            <p className='font-bold text-lg text-white'>Get in touch</p>
                            <div className="max-w-md pr-16 text-md text-gray-200">
                                Lucknow, Uttar Pradesh<br />
                                <span className='text-gray-200'>Customer Support: +91 9718398160</span><br />
                                <a className='text-gray-200'>Mail us at: info@decodiefy.com</a>
                            </div>
                            
                            <p className='font-bold text-lg text-white'>Connect with us</p>
                            <div className="flex space-x-4">
                                {/* Add social media icons */}
                                <a href="https://www.linkedin.com/company/104812334/admin/dashboard/ " target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-green-600">
                                    <FaLinkedin className="h-6 w-6" />
                                </a>
                                <a href="https://x.com/decodiefy" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-green-600">
                                    <FaTwitter className="h-6 w-6" />
                                </a>
                                <a href="https://www.facebook.com/profile.php?id=61566323053373" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-green-600">
                                    <FaFacebook className="h-6 w-6" />
                                </a>
                                <a href="https://www.instagram.com/decodiefy_technologies/" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-green-600">
                                    <FaInstagram className="h-6 w-6" />
                                </a>
                            </div>
                        </div>
                        <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-md font-semibold leading-6 text-white">Menu</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        <li className='hover:decoration-solid'>
                                            <Link to="/" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Services
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/pricing/algo" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Pricing
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/about" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Contact Us
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-10 md:mt-0">
                                    <h3 className="text-md font-semibold leading-6 text-white">Services</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        <li>
                                            <Link to="/services/api-bridge" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                API Bridge
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services/algo-trading" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Algo Trading
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services/auto-buy-sell-signal" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Auto Buy & Sell
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services/strategy-development" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Strategy Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services/it" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                IT Services
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-md font-semibold leading-6 text-white">Pricing</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        <li>
                                            <Link to="/pricing/payment-details" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Payment
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/pricing/it" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                IT Pricing
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/pricing/algo" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Algo Pricing
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-10 md:mt-0">
                                    <h3 className="text-md font-semibold leading-6 text-white">Our Policy</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        <li>
                                            <Link to="/disclaimer" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Disclaimer
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/refund-policy" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Refund Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/terms-condition" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                Terms & Conditions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/sebi-circular" className="text-md leading-6 text-gray-300 hover:text-green-600">
                                                SEBI Circular
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 border-t border-gray-400/30 pt-4 sm:mt-20 lg:mt-10">
                        <div className="text-md text-center text-white">
                            Copyright © 2024. All Rights Reserved by 
                            <Link rel="noopener" to="/"> Decodiefy Technologies</Link>.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
