import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';


import About from './pages/About';
import Home from './pages/Home';
import Services from './pages/Services';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SebiCircular from './pages/SebiCircular';
import Disclaimer from './pages/Disclaimer';
import Terms from './pages/Terms';
import RefundPolicy from './pages/RefundPolicy';
import ApiBridge from './pages/ApiBridge';
import AlgoTrade from './pages/AlgoTrade';
import AutoBuySell from './pages/AutoBuySell';
import Strategy from './pages/Strategy';
import ITServices from './pages/ITServices';
import Login from './pages/Login';
import QRPage from './components/pricing/QRPage';
import ITPricing from './components/pricing/ITPricing';
import AlgoPricing from './components/pricing/AlgoPricing';
import Maintenance from './pages/Maintenance';



// const router = createBrowserRouter([{path: '/', element:<Maintenance />}]);

const router = createBrowserRouter([
    {path: '/', element:<App />, children:[
      {path: '/', element:<Home />},
      {path: '/services', element: <Services />},
      {path:'/pricing/it', element:<ITPricing />},
      {path:'/pricing/algo', element:<AlgoPricing />},
      {path: '/about', element: <About />},
      {path: '/contact', element: <Contact />},
      {path: '/services/it', element: <ITServices />},
      {path: '/services/api-bridge', element: <ApiBridge />},
      {path: '/services/algo-trading', element: <AlgoTrade />},
      {path: '/services/auto-buy-sell-signal', element: <AutoBuySell />},
      {path: '/services/strategy-development', element: <Strategy />},
      {path: '/privacy-policy', element: <PrivacyPolicy />},
      {path: '/disclaimer', element: <Disclaimer />},
      {path: '/terms-condition', element: <Terms />},
      {path: '/refund-policy', element: <RefundPolicy />},
      {path: '/sebi-circular', element: <SebiCircular />},
      {path: '/login', element: <Login />},
      {path: '/pricing/payment-details', element: <QRPage />},
    ]}
  ])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

