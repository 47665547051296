import brokers from '../../data/brokers';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Brokers = () => {
  const settings = {
    slidesToShow: 8,
    slidesToScroll: 1,
    infinite: true,
    autoplay:true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplaySpeed: 1500,
        }
      }
    ]
  };


    return (
        <div className="relative overflow-hidden py-6 px-2">
            <h1 className="text-green-700 text-2xl font-semibold text-center py-3">
                API Bridge Software Supported in 10+ brokers
            </h1>

            <div className="my-5 mx-auto xl:w-4/5 lg:w-full md:w-full sm:w-full py-5">
            <Slider {...settings} >
                {brokers.map((b) => (
                        <img
                            key={b.title}
                            src={b.image}
                            alt={b.title}
                            className="w-16 h-16 object-contain sm:w-12 sm:h-12"
                        />
                ))}
                </Slider>
            </div>
        </div>

    );
};

export default Brokers;
