import imag from '../images/homeImage/profile.jpg';

const reviews = [
    {
        id: 1,
        name: "Rajendra Kumar Shinde",
        image: imag,
        message: "Thank you Decodiefy technologies algo software. A big thank you to your senior head Mr. Vijayraj sir to give me the right direction and get good profit continuously."
    },
    {
        id: 2,
        name: "Umakant Koli",
        image: imag,
        message: "गेल्या तीन दिवसांपासून खूप चांगला नफा आणि अंशुमन सरांनी चांगली सेवा दिली आहे. डीकोडीफाय तंत्रज्ञान आणि टीमचे खूप खूप आभार."
    },
    {
        id: 3,
        name: "Bipin Pipariya",
        image: imag,
        message: "આભાર સર અમે ખૂબ સારું કામ કર્યું અને સારું આઉટપુટ પણ મળ્યું. હું દરેકને decodiefy Technologies ની ભલામણ કરીશ, તે ખૂબ જ સારી રીતે કામ કરે છે."
    },
    {
        id: 4,
        name: "Alex Patil",
        image: imag,
        message: "Decodiefy Technologies સાથેનો મારો અનુભવ ઉત્તમ રહ્યો. તેમની ટીમે વ્યાવસાયિક રીતે અને સમયસર કામ પૂર્ણ કર્યું, અને પરિણામ અપેક્ષાથી વધુ સારું આવ્યું. હું સૌને તેમની અલ્ગો ટ્રેડિંગ સેવાઓની ભલામણ કરું છું."
    },
    // {
    //     id: 5,
    //     name: "Dharmesh Patil",
    //     image: imag,
    //     message: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. tempora eaque, modi laboriosam quod odit dignissimos soluta."
    // },
    
];

export default reviews;
