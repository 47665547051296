// Import images
// import apibridgeImage from '../images/offerImage/apibridge.jpg';
import apibridgeImage from '../images/offerImage/api.jpg';
// import algotradingImage from '../images/offerImage/algotrading.jpg';
import algotradingImage from '../images/offerImage/algo.jpg';
// import autobuyImage from '../images/offerImage/autobuy.jpg';
import autobuyImage from '../images/offerImage/auto.jpeg';
// import strategyImage from '../images/offerImage/strategy.jpeg';
import strategyImage from '../images/offerImage/strategyy.jpeg';
// import itservicesImage from '../images/offerImage/itservices.jpg';
import itservicesImage from '../images/offerImage/it.jpg';

// Offer details
const offerDetails = [
    {
        image: apibridgeImage, 
        title: "API Bridge",
        description: "API Bridge is a set of programming interface, pincode and Places is first programming interface right now. API Bridge permits you to algo trade with various stages like Amibroker, MT4, TradingView, Python, Excel, NinjaTrader and so on.",
        url: "/services/api-bridge",
    },
    {
        image: algotradingImage,  
        title: "Algo Trading",
        description: "Algorithmic trading, also known as Algo trading or automated trading, is a method of executing trades in financial markets using computer algorithms. It involves the use of pre-defined sets of rules and instructions to automatically place trades, manage positions, and execute orders without the need for human intervention.",
        url: "/services/algo-trading",
    },
    {
        image: autobuyImage, 
        title: "Auto Buy & Sell Signal",
        description: "Auto buy and sell signals in the share market refer to automated notifications or indications generated by trading systems or algorithms that suggest when to buy or sell a particular stock or security. These signals are based on predefined rules and technical indicators programmed into the trading system.",
        url: "/services/auto-buy-sell-signal",
    },
    {
        image: strategyImage,
        title: "Strategy Development",
        description: "MT4 and MT5 indicators, master advisors, calculators, and even money management formulas are the main Trading tools. Most of these tools are special indicators. These tools have one main objective, to assist brokers.",
        url: "/services/strategy-development",
    },
    {
        image: itservicesImage,
        title: "IT Service",
        description: "IT services encompass a wide range of activities aimed at supporting and enhancing an organization’s use of technology. They help ensure the smooth operation, security.",
        url: "/services/it",
    }
];

export default offerDetails;
