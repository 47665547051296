import zerodha from '../images/brokerImg/zerodha.jpg';
import angleone from '../images/brokerImg/angleone.jpeg';
import aliceblue from '../images/brokerImg/aliceblue.jpeg';
import choiceindia from '../images/brokerImg/choiceindia.png';
import fyers from '../images/brokerImg/fyers.jpg';
import upstox from '../images/brokerImg/upstox.jpg';
import kotak from '../images/brokerImg/kotak.jpg';
import dhan from '../images/brokerImg/dhan.jpeg';
import goodwill from '../images/brokerImg/goodwill.jpeg';
import spaisa from '../images/brokerImg/spaisa.jpg';

// brokers details
const brokers = [
    {
        image: zerodha, 
        title: "Zerodha",
    },
    {
        image: angleone, 
        title: "Angle One",
    },
    {
        image: aliceblue, 
        title: "Alice Blue",
    },
    // {
    //     image: demobroker, 
    //     title: "Demo Broker",
    // },
    {
        image: choiceindia, 
        title: "Choice India",
    },
    {
        image: spaisa, 
        title: "SPaisa",
    },
    {
        image: fyers, 
        title: "FYERS",
    },
    {
        image: upstox, 
        title: "Upstox",
    },
    {
        image: kotak, 
        title: "Kotak",
    },
    {
        image: dhan, 
        title: "Dhan",
    },
    {
        image: goodwill, 
        title: "Goodwill",
    },
];

export default brokers;